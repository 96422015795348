@mixin type-color($color) {
    background-color: rgba($color, 0.96);
    border-bottom: 1px solid darken($color, 10%);

}

.admin-messages {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 30;
    height: auto;
    max-height: 100%;
    // overflow-y: auto;

    .admin-message {

        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        color: $color-admin-message;

        &__close-button {
            color: $color-admin-message;
            font-size: 30px;
            background: transparent;
            border: 0;
        }

        &--info {
            @include type-color($color-info);
        }
        &--warning {
            @include type-color($color-warning);
        }
        &--alert {
            @include type-color($color-alert);
        }

        &--bottom {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

}
